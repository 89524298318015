import React, { useState } from "react";
import { graphql, useStaticQuery, navigate, Link } from "gatsby";
import TopNav from "./topnav";
import Nav from "./nav";

import Phone from "../../assets/icons/phone.svg";
import Cross from "../../assets/icons/cross.svg";
import Hero from "../hero/hero";
import SearchForm from "../search-form/search-form";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Header = ({ title, showBanner: showHero = false, heroImage: bgImage, productAdded }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [cart, setCart] = useState([]);
  const [email, setEmail] = useState('');
  const [file, setFile] = useState();
  const { logo, image1, image2 } = useStaticQuery(query);

  React.useEffect(() => {
    if (productAdded && openCart === false) {
      toggleModal();
      window.scrollTo(0, 0);
      productAdded = null;
    }
  }, undefined);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const toggleModal = () => {
    if (openCart === true) {
      setOpenCart(false);
    } else {
      const cartContent = getCartContent();
      if (cartContent.length === 0) return;
      setCart(cartContent);
      setOpenCart(true);
    }
  };

  const getCartContent = () => {
    if (typeof window === 'undefined') {
      return [];
    }
    let cartContent = localStorage.getItem("ttbgCart");
    if (cartContent) {
      return JSON.parse(cartContent);
    }
    return [];
  };

  const cartContentCount = getCartContent().reduce(
    (acc, item) => (acc += +item.quantity),
    0
  );

  const removeItem = (index) => (e) => {
    e.preventDefault();
    if (typeof window === 'undefined') {
      return false;
    }
    if (cart && cart.length > index && index >= 0) {
      cart.splice(index, 1);
      const newCart = [...cart];
      localStorage.setItem("ttbgCart", JSON.stringify(newCart));
      if (newCart.length === 0) setOpenCart(false);
      setCart(newCart);
    }

    return false;
  };

  const purgeCart = () => {
    if (typeof window === 'undefined') {
      return false;
    }
    const newCart = [];
    localStorage.setItem("ttbgCart", JSON.stringify(newCart));
    if (newCart.length === 0) setOpenCart(false);
    setCart(newCart);
  };

  const handleAttachment = e => {
    setFile(e.target.files[0])
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const myForm = document.getElementById('devis-form');
    const formData = new FormData(myForm);
    // const postData = encode({
    //   ...formData.reduce((acc, p) => acc[p[0]] = p[1], {}),
    //   file
    // });
    fetch('/', {
      method: 'POST',
      // headers: { "Content-Type": "multipart/form-data" },
      body: formData

    }).then(() => {
      purgeCart();
      navigate("/remerciements");
    }
    ).catch((error) =>
      alert(error))
  }

  return (
    <>
      <TopNav />

      <header className="container relative mb-8 px-4" role="banner">
        <div className="flex justify-between items-center lg:items-end">
          <a href="#" onClick={toggleMenu} className="block lg:hidden">
            <svg fill="#123b84" viewBox="0 0 100 80" width="40" height="40">
              <rect width="100" height="15"></rect>
              <rect y="30" width="100" height="15"></rect>
              <rect y="60" width="100" height="15"></rect>
            </svg></a>
          <Link
            to="/"
            title="L'atelier du tote bag - Impression de tote bags personnalisés"
            rel="home"
          >
            <GatsbyImage
              image={getImage(logo)}
              alt="Logo de l'atelier du tote bag"
            />
          </Link>

          <div className="md:flex-grow md:ml-4">
            <div className="flex items-center justify-between lg:mb-5">
              <SearchForm />
              <div className="hidden lg:block">
                <a href="tel:+33 9 72 63 01 32">
                  <Phone className="inline-block relative -top-1" />{" "}
                  <span className="text-1.5xl font-semibold hover:text-blue">
                    +33 9 72 63 01 32
                  </span>
                </a>
              </div>
              {cartContentCount > 0 && (
                <div
                  onClick={toggleModal}
                  className="flex items-center text-blue cursor-pointer"
                >
                  <span className="hidden lg:block uppercase text-sm mr-3">Votre devis</span>
                  <div className="relative">
                    <span className="cart-icon-handle" />
                    <div className=" flex justify-center items-center w-9 h-8 border-2 border-blue font-semibold text-sm">
                      {cartContentCount}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Nav showMenu={showMenu} closeMenu={() => setShowMenu(false)} />
          </div>
        </div>
      </header>

      {(showHero || bgImage) && <Hero title={title} bgImage={bgImage} />}

      <div className={openCart ? 'flex justify-center' : 'hidden'}>
        <div className="cart-backdrop" onClick={() => setOpenCart(false)} />
        <div className="cart-modal w-full md:w-10/12 p-6 z-30 shadow rounded-2xl">
          <div className="flex flex-wrap mb-16">
            <div className="w-full md:w-6/12 z-30 flex-col pt-4">
              <div className="uppercase text-gray-600 text-xl font-semibold mb-4">
                Mes produits
              </div>
              {cart &&
                cart.map((cartItem, index) => (
                  <div className="z-30" key={index}>
                    <div className="flex mb-10">
                      <div>
                        <GatsbyImage
                          image={
                            (cartItem.variation && getImage(cartItem.variation.Image)) ||
                            (cartItem.product && getImage(cartItem.product.image.localFile))
                          }
                          style={{ marginBottom: 8,width:'120px' }}
                          alt="produit"
                        />

                      </div>
                      <div className="w-full px-6 text-sm">
                        <div className="flex justify-between items-start mb-2">
                          <span className="uppercase text-gray-500 font-semibold mb-2 mr-2">
                            {cartItem.product.title}
                          </span>

                          <button
                            type="button"
                            onClick={removeItem(index)}
                            className="bg-transparent border-none text-sm mt-1"
                          >
                            <Cross width={12} height={12} />
                          </button>
                        </div>
                        <span className="block mb-1">
                          Quantité : {cartItem.quantity}
                        </span>
                        <span className="capitalize flex items-center mb-1">
                          <span>
                            Couleur : {cartItem.color.Nom.replace("-", " ")}
                          </span>
                          <span
                            className="ml-2 p-3 rounded-md inline-block"
                            style={{ backgroundColor: cartItem.color.valeur }}
                          />
                        </span>
                        <span className="block mb-1">Marquage : {cartItem.marquage}</span>
                        <span className="block mb-1">Face(s) : {cartItem.faces}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <form
              id="devis-form"
              className="w-full md:w-6/12 flex-col p-4 bg-blue-lighter"
              onSubmit={handleSubmit}
              name="demander-devis"
              data-netlify="true"
              netlify-honeypot="potdemiel"
            >
              <input type="hidden" name="form-name" value="demander-devis" />
              <input type="hidden" id="subject" name="subject" value={`Demande de devis de ${email}`}></input>
              <p className="hidden">
                <label>Ne remplissez pas ce champ si vous êtes un humain: <input name="potdemiel" /></label>
              </p>
              <p className="text-xl font-semibold text-blue">
                Obtenir un devis
              </p>
              <label
                htmlFor="logo-import"
                className="block text-gray-600 mb-1 text-sm font-semibold"
              >
                Importer un logo
              </label>
              <input
                name="file"
                type="file"
                id="logo-import"
                className="block border-none bg-transparent shadow-none p-0 text-sm"
              />
              <div className="my-2">
                <label className="block mb-2 text-gray-500 text-sm font-semibold">
                  Votre message
                </label>
                <textarea name="message" className="block mb-8 p-2 w-full border-1 border-gray-400" />
              </div>
              <span className="text-gray-700 text-xl font-semibold ">
                Mes coordonnées
              </span>
              <div className="my-2">
                <label className="text-gray-700 text-sm font-semibold">
                  Votre Nom (obligatoire){" "}
                  <span className="text-red-danger">*</span>
                </label>
                <input name="Nom" type="text" className="mb-0" />
              </div>
              <div className="my-2">
                <label className="text-gray-700 text-sm font-semibold">
                  Votre adresse mail (obligatoire){" "}
                  <span className="text-red-danger">*</span>
                </label>
                <input required name="email" onChange={(e) => setEmail(e.target.value)} type="text" className="mb-0" />
              </div>
              <div className="my-2">
                <label className="text-gray-700 text-sm font-semibold">
                  Votre numéro de téléphone
                </label>
                <input name="tel" type="text" className="mb-8" />
              </div>
              {[0, 1, 2, 3, 4, 5].map((i) => (
                <div key={i}>
                  <input
                    type="hidden"
                    name={`produit_${i + 1}`}
                    value={cart && cart[i] ? `${cart[i].product.title} | ${cart[i].quantity} pcs | ${cart[i].color.Nom} | ${cart[i].marquage} | ${cart[i].faces} face(s)` : 'pas de produit ajouté'} />
                </div>
              ))}
              <button
                type="submit"
                className="block w-full  text-sm bg-blue text-white py-2 uppercase"
              >
                Envoyer mon devis
              </button>
              <div
                className="uppercase cursor-pointer text-sm hover:underline text-center mt-6"
                onClick={() => setOpenCart(false)}
              >
                Continuer mes achats
              </div>
            </form>
          </div>
          <div className="flex justify-evenly mb-8">
            <div className="flex flex-wrap md:space-x-4 items-center justify-center">
              <GatsbyImage
                image={getImage(image1)}
                alt="picto devis 24h"
              />
              <span className="">Votre devis en 24h</span>
            </div>
            <div className="flex flex-wrap md:space-x-4 items-center justify-center">
              <GatsbyImage
                image={getImage(image1)}
                alt="picto livraison 7 jours"
              />
              <span className="">Livraison 7j</span>
            </div>
            <div className="flex flex-wrap md:space-x-4 items-center justify-center">
              <GatsbyImage
                image={getImage(image2)}
                alt="picto livraison gratuite"
              />
              <span className="">Livraison gratuite</span>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

const query = graphql`
  query {
    logo: file(
      relativePath: { eq: "Logo-latelierdutotebag.png" }
      sourceInstanceName: { eq: "logos" }
    ) {
      childImageSharp {
        gatsbyImageData(
          height: 100
          backgroundColor: "white",
          quality: 100,
        ) 
      }
    }
    image1: file( relativePath: { eq: "devien-en-24h-latelier-du-tote-bag-1.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 80
          height: 80
        ) 
      }
    }
    image2: file(
      relativePath: {
        eq: "livraison-express-latelier-du-tote-bag.png"
      }
      sourceInstanceName: { eq: "uploads" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 80, height: 80) 
      }
    }
  }
`;

export default Header;
