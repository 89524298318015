exports.productParams = {
  // urlPrefix: "/sacs-en-tissu/",
  urlPrefix: "/",
};

exports.productUrl = (product) => {
  return `${this.productParams.urlPrefix}${
    product.categories.length ? product.categories[0].slug + "/" : ""
  }${product.slug}/`;
};

exports.createProducts = async ({ graphql, actions }) => {
  const { createPage } = actions;
  const result = await graphql(
    `
      {
        products: allStrapiProduct(filter: { status: { eq: "published" } }) {
          edges {
            node {
              slug
              status
              categories {
                id
                slug
              }
            }
          }
        }
      }
    `
  );

  if (result.errors) {
    throw result.errors;
  }

  const products = result.data.products.edges;
  const ProductTemplate = require.resolve("./src/templates/product.js");

  products.forEach((product, index) => {
    createPage({
      path: this.productUrl(product.node),
      component: ProductTemplate,
      context: {
        ...product.node,
      },
    });
  });
};
