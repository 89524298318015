exports.categoryParams = {
  urlPrefix: "/categorie-produit/",
};

exports.createCategories = async ({ graphql, actions }) => {
  const { createPage } = actions;
  const result = await graphql(
    `
      {
        categories: allStrapiCategory {
          edges {
            node {
              slug
              strapiId
            }
          }
        }
      }
    `
  );

  if (result.errors) {
    throw result.errors;
  }

  const categories = result.data.categories.edges;
  const CategoryTemplate = require.resolve("./src/templates/category.js");

  categories.forEach((category, index) => {
    createPage({
      path: `${this.categoryParams.urlPrefix}${category.node.slug}`,
      component: CategoryTemplate,
      context: {
        ...category.node,
      },
    });
  });
};
