import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import Search from "../../assets/icons/search-orange.svg";
import { productUrl } from "../../../node-product";

const SearchForm = () => {
  const data = useStaticQuery(graphql`
  query {
    localSearchProducts:localSearchProducts {
      index
      store
    }
    allStrapiProduct: allStrapiProduct {
      nodes {
        id
        title
        slug
      }
    }
  }
`);
  const {
    localSearchProducts: { index, store },
    allStrapiProduct: { nodes },
  } = data;
  const [query, setQuery] = useState("")
  const results = useFlexSearch(query, index, store)
  return (<div className="hidden md:block flex-grow max-w-sm">
    <form
      method="get"
      id="searchform"
      className="flex flex-col relative"
      action="/categorie-produit"
      role="search"
    >
      <div className="flex">
        <input
          value={query}
          autoComplete="off"
          onInput={(e) => setQuery(e.target.value)}
          onChange={(e) => setQuery(e.target.value)}
          type="text"
          name="query"
          className=" mb-0 rounded-3xl"
          placeholder="Totebags, cabas, pochettes..."
        />
        <button className="absolute top-2 right-4 w-4 h-5" type="submit">
          <Search className="w-full h-full" />
        </button>
      </div>
      {results && results.length > 0 && (<ul className="bg-white p-4 shadow rounded-lg text-sm absolute top-10 z-10">
        {results.slice(0, 6).map((result,i) => (
          <li key={i} className="py-2 hover:bg-blue-lighter" style={{ whiteSpace: 'nowrap' }} >
            <Link to={productUrl({ ...result})}>
              {result.title}
            </Link>
          </li>
        ))}
      </ul>)}
    </form>
  </div >
  );
}


export default SearchForm;
