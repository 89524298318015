import React from "react";

const SectionTitle = ({ children, noMargin = false, border = true, blue = false, color, mb = 8, h3 = false }) => {
  if (blue)
    return <>
      <div className="bg-blue h-2 my-4"></div>
      {h3 &&
        <h3 className={` text-3xl text-center py-2 mb-${mb} ${color ? "text-" + color : "text-blue"}`}>
          {children}
        </h3>
      }
      {!h3 && <h2 className={` text-3xl text-center py-2 mb-${mb} ${color ? "text-" + color : "text-blue"}`}>
        {children}
      </h2>
      }
    </>
  else return <div className={`flex ${noMargin ? '' : 'mt-12 '} mb-8`}>
    {border && <div
      className={`h-4 border-b-1 flex-grow mb-${mb} ${color ? "border-" + color : "border-blue"}`}
    ></div>}
    {h3 &&
      <h3 className={` text-3xl ${border ? 'text-center px-6' : ''}  mb-0 my-0 ${color ? "text-" + color : "text-blue"}`}>
        {children}
      </h3>
    }
    {!h3 && <h2 className={` text-3xl ${border ? 'text-center px-6' : ''}  mb-0 my-0 ${color ? "text-" + color : "text-blue"}`}>
      {children}
    </h2>
    }
    {border && <div
      className={`h-4 border-b-1 flex-grow ${color ? "border-" + color : "border-blue"}`}
    ></div>}
  </div>
};

export default SectionTitle;
