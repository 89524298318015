import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Footer = () => {
  const data = useStaticQuery(query);
  return (
    <footer >
      <div className="container">
        <div className="grid grid-cols-3 text-sm">


          <div>
            <div className="font-semibold text-lg text-blue">L'Atelier du Tote Bag</div>
            <ul>
              <li><Link to="/l-atelier-du-tote-bag">Qui sommes-nous ?</Link></li>
              <li><Link to="/tote-bags-inspirations">Nos réalisations</Link></li>
              <li><Link to="/contacter-l-atelier-du-tote-bag">Nous contacter</Link></li>
              <li><Link to="/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise">Le blog</Link></li>
              <li><Link to="/questions-fabrication-tote-bags-personnalises">Les questions / réponses</Link></li>
              <li><Link to="/grossiste-tote-bag">Grossiste tote bag</Link></li>
              {/* <li><Link to="/delai-de-livraison"><b>Délai de livraison</b></Link></li> */}
            </ul>


            <div className="font-semibold text-lg text-blue">Les marquages</div>
            <ul>
              <li><Link to="/impression-serigraphie">La sérigraphie</Link></li>
              <li><Link to="/impression-numerique">L'impression numérique</Link></li>
              <li><Link to="/impression-transfert">L'impression transfert</Link></li>
              <li><Link to="/impression-broderie">La broderie</Link></li>

              {/* <li><Link to="/broderie-3d">Broderie 3D</Link></li> */}
              {/* <li><Link to="/tote-bags-blanc-a-personnaliser" >Tote bag blancs personnalisables</Link></li> */}
              {/* <li><Link to="/tote-bags-vierge-a-personnaliser" >Tote bag vierge à personnaliser (couleur de votre choix)</Link></li> */}
            </ul>
          </div>

          <div>
            {/* <div className="font-semibold text-lg text-blue">Nos produits par matière</div> */}
            {/* <ul> */}
            {/* <li><Link to="/categorie-produit/sac-tissu-personnalise-publicitaire" >Sacs en coton</Link></li> */}
            {/* <li><Link to="/categorie-produit/sacs-publicitaires-coton-biologique" >Sacs en coton biologique</Link></li> */}
            {/* <li><Link to="/categorie-produit/sacs-en-toile-de-jute-personnalises" >Sacs en toile de jute</Link></li> */}
            {/* </ul> */}
            {/* <ul>
              <li><Link to="/categorie-produit/tote-bags-personnalises-coton-biologique" >Tote bags personnalisés en coton biologique </Link></li>
              <li><Link to="/categorie-produit/pochettes-publicitaires-bio-personnalisees" >Pochettes personnalisables bio</Link></li>
              <li><Link to="/categorie-produit/tote-bags-grand-format-xxl-personnalises" >Tote bags personnalisés grand format</Link></li>
              <li><Link to="/categorie-produit/sacs-coton-ecologique-personnalises" >Sacs en coton personnalisables</Link></li>
            </ul> */}

            <div className="font-semibold text-lg text-blue">Nos produits personnalisables </div>
            <ul>
              <li><Link to={'/categorie-produit/tote-bags-personnalises'}>Tote bags personnalisés</Link></li>
              <li><Link to={'/categorie-produit/cabas-personnalises'}>Sacs cabas personnalisés</Link></li>
              <li><Link to={'/categorie-produit/sac-pochon-publicitaire-personnalise'}>Pochons personnalisés</Link></li>
              <li><Link to={'/categorie-produit/trousses-personnalisees'}>Trousses personnalisées</Link></li>
              <li><Link to={'/categorie-produit/sac-a-dos-personnalise-publicitaire'}>Sacs à dos publicitaires personnalisés</Link></li>
              <li><Link to={'/categorie-produit/sac-de-sport-personnalise-publicitaire'}>Sacs de sport publicitaires personnalisés</Link></li>
              <li><Link to={'/categorie-produit/sac-a-pain-tissu-personnalise-publicitaire'}>Sacs à pain publicitaire personnalisé</Link></li>
              <li><Link to={'/categorie-produit/tote-bags-grand-format-xxl-personnalises'}>Tote bags personnalisés grand format</Link></li>
              <li><Link to={'/tote-bag-personnalises-livraison-rapide'}>Tot bag personnalisés avec livraison rapide</Link></li>
              <li><Link to={'/tote-bags-noirs-personnalises'}>Sacs personnalisés avec logo</Link></li>

            </ul>

          </div>


          <div>
            {/* <div className="font-semibold text-lg text-blue">Nos produits par couleur</div> */}
            {/* <ul> */}
            {/* <li><Link to={"/tote-bags-noirs-personnalises"}>Tote bags noir personnalisés</Link></li> */}
            {/* <li><Link to={"/tote-bags-bleus-personnalises"}>Tote bags bleu personnalisés</Link></li> */}
            {/* </ul> */}

            <div className="font-semibold text-lg text-blue">Fabrication sur mesure</div>
            <ul>
              <li><Link to={'/tote-bag-sur-mesure'}>Sacs personnalisés sur mesure</Link></li>
            </ul>

            <div className="font-semibold text-lg text-blue">Nous contacter</div>


            <ul>
              <li>
                <a href="mailto: devis@latelierdutotebag.com">devis@latelierdutotebag.com</a>
              </li>
              <li className="flex space-x-6">
                <a
                  className="block border-2 border-blue-light rounded-full p-2 hover:bg-blue-light"
                  href="https://www.facebook.com/latelierdutotebag"
                  target="_blank"
                  rel="nofollow, noreferrer"
                >
                  <Facebook width="20" className="text-white fill-current" />
                </a>
                <a
                  className="block border-2 border-blue-light rounded-full p-2 hover:bg-blue-light"
                  href="https://www.instagram.com/latelierdutotebag"
                  target="_blank"
                  rel="nofollow, noreferrer"
                >
                  <Instagram width="20" className="text-white fill-current" />
                </a>
              </li>
              <li>
                <Link to="/conditions-generales-de-vente">
                  Conditions générales de vente
                </Link>
              </li>
              <li>
                <Link to="/mentions-legales">Mentions légales</Link>
              </li>
              <li>
                <Link to="/cookie-preferences">Politique de confidentialité</Link>
              </li>
            </ul>
            <div className="w-32">
              <GatsbyImage
                image={getImage(data.image1)}
                className="w-full"
                alt="Logo de l'atelier du totebag"
              />
            </div>
          </div>

          {/* <div>
            <ul>
              <li><span className="text-base font-semibold text-white">L'atelier du tote bag</span></li>
              <li><Link to="/l-atelier-du-tote-bag">Qui sommes-nous ?</Link></li>
              <li><Link to="/tote-bags-inspirations">Nos réalisations</Link></li>
              <li><Link to="/contacter-l-atelier-du-tote-bag">Nous contacter</Link></li>
              <li><Link to="/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise">Le blog</Link></li>
              <li><Link to="/questions-fabrication-tote-bags-personnalises">Les questions / réponses</Link></li>
              <li><Link to="/grossiste-tote-bag">Grossiste tote bag</Link></li>
            </ul>
          </div> */}


        </div>
      </div>
      <div className="text-xs text-gray-200 text-center mt-4">
        © Copyright {new Date().getFullYear()}, L'atelier du Tote bag
      </div>
    </footer >
  );
};

const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "Logo-latelierducoton.png" }
      sourceInstanceName: { eq: "uploads" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 150, quality: 100) 
      }
    }
  }
`;

export default Footer;
