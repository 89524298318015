import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { categoryParams } from "../../../node-category";

const CategoryTree = ({ currentCat, inputCat }) => {
  const data = useStaticQuery(query);
  let rootCat = inputCat || currentCat;
  const shouldFindRoot = inputCat === undefined
  if (shouldFindRoot) {
    while (rootCat && rootCat.strapiParent)
      rootCat = data.allStrapiCategory.nodes.find(n => n.strapiId === rootCat.strapiParent.id)
  }
  const children = data.allStrapiCategory.nodes.filter(n => n.strapiParent && n.strapiParent.id === rootCat.strapiId)
  
  return (
    <ul className="product-categories">
      <li >
        <Link className={currentCat.strapiId === rootCat.strapiId ? 'underline' : ''} to={categoryParams.urlPrefix + rootCat.slug}>{rootCat.name}</Link>
        {children.length > 0 && (
          <div className="pl-4">
            {children.map((cat) =>
              <CategoryTree key={cat.strapiId} currentCat={currentCat} inputCat={cat}></CategoryTree>
            )}
          </div>
        )}
      </li>

    </ul>
  );
};

const query = graphql`
  query {
    allStrapiCategory(sort: {position: ASC})  {
      nodes {
        slug
        id
        position
        name
        strapiId
        strapiParent {
          id
        }
        strapiChildren {
          id
        }
        products {
          id
        }
      }
    }
  }
`;

export default CategoryTree;
