import React from "react";
const SeparatorComponent = ({ center = false }) => (
  <div
    className={`flex mb-4 border-b-4 border-gray-300 w-8 ${
      center === true ? "mx-auto" : ""
    } `}
  ></div>
);

export default SeparatorComponent;
